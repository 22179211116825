<template>
  <minimal-bg-header-layout header-solo-logo>
    <div>
      <div class="container mx-auto p-6 content-center md:py-12 lg:px-12 md:w-1/2 xl:w-4/5">
        <div class="flex flex-col px-2 py-8 shadow-2xl rounded bg-white lg:px-8">
          <div class="flex flex-col w-full text-center mb-4">
            <h1 class="sm:text-3xl text-2xl font-bold mb-4">
            ¡Bienvenido!
            </h1>
          </div>
          <el-steps :active="active"  align-center>
            <el-step
              :status="active !== 0 ? 'success' : 'process'"
              title="Mi negocio"
              icon="el-icon-info" />
            <el-step
              :status="active !== 1 && active > 1 ? 'success' : ''"
              title="Usuarios y permisos"
              icon="el-icon-user" />
            <el-step
              :status="active !== 2 && active > 1 ? 'success' : ''"
              title="Politicas y plantillas"
              icon="el-icon-info" />
          </el-steps>
          <div>
            <div v-if="active === 0">
              <business-form saveButtonLabel="Continuar" @onMyBusinessFormSave="onMyBusinessFormSave" />
            </div>
            <div v-if="active === 1">
              <div class="w-full xl:px-36 sm:px-8">
                <div class="flex flex-row items-center py-4">
                  <h1 class="text-lg font-bold inline-flex items-center">
                    <strong>&nbsp;Usuarios y Permisos</strong>
                  </h1>
                  <div class="ml-auto">
                    <sa-button
                      type="primary"
                      class="items-center px-3 py-3 font-bold rounded"
                      @click="next">
                      Continuar
                    </sa-button>
                  </div>
                </div>
                <div class="flex flex-col space-y-6">
                  <div class="w-full xl:w-2/3 mb-4">
                    <strong>Enviar invitacion por correo</strong>
                    <div class="flex mt-2">
                      <el-input class="w-2/3 mr-2" v-model="email" />
                      <div class="w-1/3">
                        <el-button @click="sendInvite">Enviar</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="active === 2">
              <policies-and-templates-form
                saveButtonLabel="Terminar"
                :callback="start" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </minimal-bg-header-layout>
</template>
<script>
import error from '@/mixins/error';
import { Regex } from '@/utils/emails';
import { AccountTypeEnum } from '@/constants';
import AdminService from '@/services/AdminService';
import { LOCAL_STORAGE_SAMII_KEYS, setItem } from '@/utils/storage';

export default {
  name: 'OnboardingIndex',
  mixins: [error],
  components: {
    MinimalBgHeaderLayout: () => import('@/components/layouts/MinimalBgHeaderLayout'),
    BusinessForm: () => import('@/components/forms/BusinessForm'),
    PoliciesAndTemplatesForm: () => import('@/components/forms/PoliciesAndTemplatesForm'),
  },
  data() {
    return {
      email: undefined,
      active: 0,
    }
  },
  mounted() {
    setItem(LOCAL_STORAGE_SAMII_KEYS.QUICK_START_STATUS, 'undone');
  },
  methods: {
    async onMyBusinessFormSave(model) {
      const formData = new FormData();
      formData.append('id', model.id);
      if (model.logo) formData.append('logo', model.logo);
      if (model.name) formData.append('name', model.name);
      if (model.phone) formData.append('phone', model.phone);
      if (model.address) formData.append('address', model.address);
      if (model.country) formData.append('country', model.country);
      if (model.currency) formData.append('currency', model.currency);
      if (model.timezone) formData.append('timezone', model.timezone);
      if (model.phoneCountryCodeId) formData.append('phoneCountryCodeId', model.phoneCountryCodeId);
      if (model.dateFormat) formData.append('dateFormat', model.dateFormat);
      try {
        await AdminService.MyBusiness.update(formData);
        this.next();
      } catch (e) {
        this.$toastr.e('Error desconocido. Por favor, vuelva a intentarlo.');
      }
    },
    async sendInvite() {
      if (!Regex.test(this.email)) {
        this.$toastr.e('Formato de correo electronico invalido');
        return;
      }
      try {
        await AdminService.UsersAndPermissions.sendInvite({ email: this.email.toLowerCase(), accountType: AccountTypeEnum.Technical });
        this.email = undefined;
        this.$toastr.s('Invitacion enviada con exito');
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      }
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    start() {
      this.$router.push({ name: 'home.index' });
    },
  }
}
</script>
